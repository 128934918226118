.profile-header-title {
    background-color: var(--buildid-yellow);
    display: flex;
    padding-top: 20px;
}

.profile-header-title h1 {
    color: var(--buildid-blue);
    margin: 0;
}

.profile-header-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.cardList {
    max-height: 515px !important;
    overflow: scroll !important;
}


.card-overflow {
    max-height: 515px !important;
    overflow: scroll !important;
}

.profile-pic {
    height: 130px;
    width: 130px;
    object-fit: cover;
}

/* Changes flex-direction responsively */
@media screen and (max-width: 1000px) {
    .details {
        flex-direction: column;
    }

    .details>* {
        width: 100%;
    }
}

.contact-info-item {
    padding: 5px;
}


.summary-box {
    background-color: var(--buildid-yellow);
    text-align: center;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.summary-box h3 {
    margin: 0;
}

.summary-box h4 {
    margin: 0;
    color: var(--buildid-blue);
    font-size: 14px;
}


.profile-experience-project-item {
    margin: 10px 0px 0px 10px;
    padding: 10px 10px 10px 10px;
    background-color: var(--buildid-lightgrey);
    border-radius: 5px;
}

.modal-otp {
    background-color: white;
    border-radius: 10px;
    margin: 20px;
    padding-top: 10px;
    width: 500px;
}

.experience-form::-webkit-scrollbar { 
     height: 0;
     width: 0;
}