.contentsearch-tutorial-2-body{
    width: 95%;
    margin-left: 188px;
}

.contentsearch-tutorial-2-container{
    color: #000;
    margin-top: 5%;
}

.contentsearch-tutorial-3-body{
    display: flex;
    align-items: center;
    height: 100%;
}

.contentsearch-tutorial-3-container{
    color: white;
    margin-top: 10px;
    margin-bottom: 40px;
}

.contentsearch-tutorial-3-inner-container{
    border: 1px solid #8D8D8D;
    border-radius: 5px;
    padding: 9px !important;
    margin-left: 90px !important;
}

.contentsearch-tutorial-3-chips{
    background-color: #fff !important;
    margin-top: 8.6px;
    margin-left:7px;
    padding: 2px;
}