.content{
    display: flex;
    text-align: left;
}
.itemicon{
    height: 40px; 
    width: 40px;
    margin-bottom: -3px;
}
.heading{
    padding: 10px;
}
.primary{
    color: #002884;
}
.welcometext{
    color: #002884;
     margin: 10px;
}
.welcomebuild{
    margin-bottom: -20px ;
    color: #002884;
}
.styleitemtext{
    text-align: left; color: #002884; margin-top:8px
}

.styleitemheading{
    margin-bottom: -20px;
     color: #002884 
}
.headicon{
   color:#8D8D8D
}
.logonbutton{
    display: flex; justify-content: space-between; align-items: center
}
.dialogcontent{
    width: 85%; margin: 0 auto
}
.taketour{
    display: flex; justify-content: center
}