.project-map-container{
	flex-grow: 1;
}

.project-map-pin-label{
	border: 3px solid black;
	border-radius: 10px;
	padding: 5px;
	background-color: var(--buildid-white);
	bottom: 50px;
    left: 0;
    position: relative;
}

.project-overlay-container{
	position: absolute;
	max-height: 100%;
	width: 450px;
	display: flex;
	flex-direction: column;
}

/* Fixed height and proper scaling of img for ProjectDetail's Carousel */
.project-detail-carousel-image{
	height: 337px;	/* project-overlay-container has a width of 450; 337 x 450 is a 4:3 ratio */
	background-color: var(--buildid-grey);
}
.project-detail-carousel-image img{
	object-fit: cover;	/* Fill container, may clip part of image */
	/* height and width 100% ensure that it's centered if it's clipped */
	height: 100%;
	width: 100%;
}

/* new code sanket 18-03-2024 */

.project-list {
	border-bottom: 1px solid #80808061;
    border-radius: 0px !important;
	box-shadow:  none !important;
}