
.client-content-header {
   margin-top: 0.8rem;
}

.ticket-card {
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

/* .flex-container-left {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
} */

.user-info {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user-image {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.user-name {
  margin-top: 5px;
}

.date-info {
  display: flex;
  align-items: center;
}

.item-date {
  margin: 0;
}

/* model  */
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: white;
  box-shadow: 24px;
  padding: 16px;
  border-radius: 10px;
}

.ticket-image {
  width: 100%;
  border-radius: 10px;
}

.description-text {
  margin-top: 10px;
}

/************* Picture board *****************/

.client-heading {
  color: #ffff;
  font-size: 17px;
  padding: 14px;
  font-weight: 600;
  /* margin-left: 10px; */
}

.client-main {
    padding: 20px;
    padding-top: 2.5rem;
    background: rgb(242, 242, 242);
}

.client-home-height {
  min-height: calc(100vh - 50px);
}

.client-main .pictureboard-card .board-card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 16%);
  border-radius: 15px;
}

.client-main .pictureboard-card .board-card .card-title {
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  padding: 10px;
  color: #000;
  cursor: pointer;
}

.client-main .pictureboard-card .board-card .board-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.client-main .pictureboard-card .board-card .board-content {
  padding: 16px !important;
}

.client-main .pictureboard-card .board-card .board-footer .profile-detail {
  display: flex;
  align-items: center;
  gap: 10px;
}

.client-main .pictureboard-card .board-card .board-footer .profile-detail .profile-name {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 500;
}

.clc_item{
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 0px -5px rgba(0, 0, 0, 0.5);
  margin: 10px 0px;
}
.clc_body_ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Adjust the number of lines to your needs */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


/************* Board content *****************/

.client-main .client-content .link-title {
  font-size: 17px;
  font-weight: 500;
}

.client-main .client-content .my-application,
.client-main .client-content .recent-text {
     font-size: 17px;
     font-weight: 500;
}


/****************** Expenses Detail **********************/

.expense-detail {
  background: white;
  padding: 0px 15px;
  border-radius: 15px;
  margin: 2rem 25px 20px 20px;
}

/* Gallary */

.fixed-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.fixed-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* background-color: #A52A2A; */
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.plus-button {
  width: 150px;
  height: 150px;
  border-radius: 20%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: black;
  border: '5px solid black';
  cursor: pointer;
  margin-left: 10px;
}

.timesheet-detail .job-imglist {
  overflow: scroll;
  max-width: 1250px;
  flex-wrap: nowrap;
}

.timesheet-detail .job-imglist::-webkit-scrollbar {
   height: 2px;
}

.postion-sticky {
  position: sticky;
  top: 58px;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
}
 
.height-auto {
  height: auto !important;
}


.client-picture-board-scrolling {
  max-height: calc(100vh - 170px);
  overflow: auto;
}

.client-home-red {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.client-home-active {
  color: #ae2220;
  background-color: #F4DBDC;
}

.clc_item{
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 0px -5px rgba(0, 0, 0, 0.5);
  margin: 10px 0px;
}

.clc_body_ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Adjust the number of lines to your needs */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Site Filing */
.modal-content {
  padding: 20px;
  background: white;
  width: 500px;
  margin: 100px auto;
  border-radius: 10px;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.file-previews {
  margin-top: 20px;
}

.file-preview-item {
  margin-bottom: 20px;
}

.file-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-file-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 16px;
}

.file-name {
  flex-grow: 1;
  margin-left: 10px;
}

.file-description {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  resize: none;
}

.preview-image {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

.add-files-btn {
  background-color: #f44336;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.submit-btn {
  margin-top: 20px;
  width: 100%;
}