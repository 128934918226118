.navbar {
    width: 100%;
    padding: 12px !important;
}

.navbar-list-item {
    background-color: #dadada;
    border-radius: 5px;
    width: 260px;
    margin-bottom: 3px;
    cursor: pointer;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.navbar-list-item .text-bold .MuiListItemText-primary {
    font-weight: 700;
    font-size: 0.8rem;
}

.list-item-hower .menu-font-size .MuiListItemText-primary {
    font-size: 0.8rem !important;
}

.group-name .menu-font-size .css-10hburv-MuiTypography-root {
    font-size: 0.8rem !important;
    cursor: pointer;
}


.navbar .nav-paragraph {
    font-size: 12px;
    font-weight: 600;
    color: #4f4f4f;
    margin-top: 10px;
}

.list-item-hower {
    margin-bottom: 5px;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.list-item-hower:hover {
    background-color: #dadada54;
    transition: 0.3s;
    border-radius: 5px;
    cursor: pointer;
}

.child-list-active:hover {
    background-color: #ffffff;
    transition: 0.3s;
    border-radius: 5px;
    cursor: pointer;
}

.child-list {
    margin-left: 35px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 3px;
}

.child-list-active {
    margin-left: 15px;
    border-top-right-radius: 10;
    border-bottom-right-radius: 10;
    margin-bottom: 3px;
}

.list-item-icon-width {
    min-width: 35px !important;
}

.drawer-header-main {
    display: flex !important;
    justify-content: center !important;
    background-color: #ffbc0a;
    height: 48px;
}

/* client header */

.client-header-main {
    display: flex !important;
    justify-content: center !important;
    /* background-color: rgb(165, 42, 42); */
    height: 58px;
}


.drawer-header-div {
    display: flex;
    justify-content: center;
}

.drawer-header-div-maplogo {
    display: flex;
    justify-content: right;
    padding: 14px 0px;
}

/**** Profile Menu Start ****/

.profile-menu {
    width: 300px;
    top: 50px !important;
    left: 1230px !important;
}

.Notification-menu {
    width: 360px;
    max-height: 465px;
}

.Notification-menu-color {
    background-color: rgb(242, 242, 242) !important;
}

.background-notification-location{
    background-color: rgb(242, 242, 242) !important;
	top: 45px !important;
    left: 1072px !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.profile-menu .profie-name {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
}

.profile-menu .trade-name {
    font-size: 13px;
}

.profile-menu .profile-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #ffffff;
    padding-bottom: 10px;
    border-bottom: 1px solid #cccccc;
}

.profile-menu .profile-item:hover {
    background-color: #ffffff;
}


.profile-menu .profile-item:focus {
    background-color: #ffffff !important;
}

.profile-menu .profile-item .builderbuks-count {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: baseline;
}

.profile-menu .profile-item .earn-builder {
    font-size: 13px;
    color: #083db8;
}

.profile-menu .profile-item .sites-count {
    background: rgb(8, 61, 184);
    border-radius: 5px;
    padding: 10px 13x;
    padding: 5px 0px;
    text-align: center;
    justify-content: center;
    width: 80px;
}

.profile-menu .profile-item .sites-count .count {
    margin-bottom: 0px;
    font-size: 17px;
    font-weight: 600;
    color: #ffffff;
}

.profile-menu .last-menu {
    gap: 10px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.profile-menu .last-menu .page-link {
    font-size: 12px;
    color: #8d8d8d;
    font-weight: 500;
    margin-bottom: 0px;
}

.drawer-header-link {
    display: flex;
    justify-content: center;
    max-width: 90%;
    max-height: 45px;
}

.drawer-header-logo {
    max-height: 47px;
}

.drawer-header-menulist {
    overflow: scroll;
    width: 100%;
    scrollbar-width: none;
    height: 92vh;
    background-color: rgb(242, 242, 242);
}

.version {
    font-size: 14px;
    background-color: rgb(242, 242, 242);
    color: #777777;
    padding-bottom: 15px;
}

.topbar-div-width-chrome {
    position: fixed;
    z-index: 9;
    width: -webkit-fill-available;
}

.topbar-div-width {
    position: fixed;
    z-index: 9;
    width: -moz-available !important;
}

.topbar-body {
    margin-top: 3rem;
}

.topbar-main {
    background-color: #ffbc0a !important;
    color: gray !important;
    box-shadow: none !important;
    padding: 0px !important;
}

.topbar-main .topbar-toolbar {
    min-height: 48px;
    padding: 0px !important;
}

.topbar-main .search-buildid {
    background: #ffffff;
}

.topbar-main .search-buildid:hover {
    background: #ffffff;
}

.builder-bucks-bar-main {
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: #ffbc0a !important;
}

.builder-bucks-bar-header-chrome {
    position: fixed;
    z-index: 9;
    width: -webkit-fill-available;
    margin-top: -48px;
    background-color: #FFBC0A;
}

.builder-bucks-bar-header {
    position: fixed;
    z-index: 9;
    width: -moz-available;
    margin-top: -48px;
    background-color: #FFBC0A;
}

.accordionSummary-height {
    height: 35px;
    width: 100%;
    padding-right: 30px !important;
}

.builder-bucks-bar-header-div {
    display: flex;
    justify-content: center;
    width: 100%;
}

.accordionSummary-font {
    font-size: 1.5em;
    font-weight: 700;
    margin: 0;
}

.builder-bucks-bar-child-div {
    padding: 0 20%;
    padding-top: 15px;
    padding-bottom: 5px;
}

/* .css-1p823my-MuiListItem-root {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
} */

/* .css-10hburv-MuiTypography-root {
    font-size: 0.8rem !important;
} */

.navbar .css-1e9lk82-MuiListItemAvatar-root {
    min-width: 35px
}

.navbar .css-qv4zf-MuiAvatar-root {
    height: 20px;
    width: 20px;
}


.MuiDrawer-docked .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    background-color: rgb(242, 242, 242);
}


.group-name {
    padding-left: 0px !important;
    gap: 7px;
}

.icon-font-size {
    font-size: small;
    height: 0.8em !important;
    width: 0.8em !important;
}
.drawer-menu-icon{ 
display: none !important;
}

.profile-menuCard-with-tutorial{
    left: 65px !important;
    top: 7px !important;
}

/* .profile-menuCard-withOut-tutorial{
    left: 30px !important;
    top: 7px !important;
} */
.builderbuks-fontSizes{
    font-size: 14px !important;
   }
   .site-info-stack{
    margin-top: 10px;
   }

   
@media screen and (max-width: 768px) {
    .profile-menu .profie-name {
        margin-bottom: 0px;
        font-size: 12px;
    }
    .profile-menu .trade-name {
        font-size: 11px;
    }

    .site-info-stack{
        margin-top: 0px;
       }
    .profile-menu .profile-item .builderbuks-count {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: baseline;
        font-size: 12px;
    }
   .builderbuks-fontSizes{
    font-size: 11px !important;
   }
    .profile-menu {
        width: 255px !important;
    }
    .profile-menu .profile-item .sites-count {
        background: rgb(8, 61, 184);
        border-radius: 5px;
        padding: 2px 0px;
        text-align: center;
        justify-content: center;
        width: 70px;
    }
    .profile-menu .profile-item .sites-count .count {
        margin-bottom: 0px;
        font-size: 12px;
        color: #ffffff;
    }
    .menu-list-height{
        min-height: 15px !important;
        padding-top: 0px !important;
    }

    .profile-menu .last-menu .page-link {
        font-size: 10px !important;
    }
    .drawer-menu-icon{
        display: inline-flex !important;
        }
        /* .topbar-div-width-chrome {
            position: fixed;
            z-index: 9;
            width: -webkit-fill-available;
            width: -moz-available;
        }
        
        .topbar-div-width {
            position: fixed;
            z-index: 9;
            width: -moz-available;
            width: -webkit-fill-available;
        } */

        .profile-menuCard-with-tutorial{
            left: 100px !important;
            top: -5px !important;
        }
        /* .profile-menuCard-withOut-tutorial{
            left:10px !important;
            top: -100px !important;
        } */
}

@media screen and (max-width: 595px) {
    .profile-menu .profie-name {
        margin-bottom: 0px;
        font-size: 12px;
    }
    .profile-menu .trade-name {
        font-size: 11px;
    }

    .site-info-stack{
        margin-top: 0px;
       }
    .profile-menu .profile-item .builderbuks-count {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: baseline;
        font-size: 12px;
    }
   .builderbuks-fontSizes{
    font-size: 11px !important;
   }
    .profile-menu {
        width: 255px !important;
    }
    .profile-menu .profile-item .sites-count {
        background: rgb(8, 61, 184);
        border-radius: 5px;
        padding: 2px 0px;
        text-align: center;
        justify-content: center;
        width: 70px;
    }
    .profile-menu .profile-item .sites-count .count {
        margin-bottom: 0px;
        font-size: 12px;
        color: #ffffff;
    }
    .menu-list-height{
        min-height: 15px !important;
        padding-top: 0px !important;
    }

    .profile-menu .last-menu .page-link {
        font-size: 10px !important;
    }
    .drawer-menu-icon{
        display: inline-flex !important;
        }
        /* .topbar-div-width-chrome {
            position: fixed;
            z-index: 9;
            width: -webkit-fill-available;
            width: -moz-available;
        }
        
        .topbar-div-width {
            position: fixed;
            z-index: 9;
            width: -moz-available;
            width: -webkit-fill-available;
        } */

        .profile-menuCard-with-tutorial{
            left: 100px !important;
            top: -5px !important;
        }
        /* .profile-menuCard-withOut-tutorial{
            left:10px !important;
            top: -100px !important;
        } */
}
@media screen and (min-width: 768px) {

    .navbar {
        margin-left: 0px !important;
    }

    /* .topbar-body {
        margin-top: 48px;
    } */

    .builder-bucks-bar-header {
        margin-top: -48px !important;
    }

    .accordionSummary-height {
        height: 15px !important;
        padding-right: 30px !important;
    }

    .accordionSummary-font {
        font-size: 1.2em !important;
    }

    .builder-bucks-bar-child-div {
        padding: 0 !important;
        padding-top: 10px !important;
    }
    .drawer-header-logo {
        max-height: 35px;
    }
    .drawer-header-div {
        padding: 0px 5px;
        display: flex;
        justify-content: center;
    }
  

        .topbar-main .topbar-toolbar {
            padding-right: 15px !important;
        }
        /* .topbar-div-width-chrome {
            position: fixed;
            z-index: 9;
            width: -webkit-fill-available;
            width: -moz-available;
        }
        
      .topbar-div-width {
            position: fixed;
            z-index: 9;
            width: -moz-available;
            width: -webkit-fill-available;
        }  */
}

.mini-drawer-width {
    width: 200px;
}

@media screen and (min-width: 1920px) {
    .navbar-list-item .text-bold .MuiListItemText-primary {
        font-weight: 700;
        font-size: 1.1rem !important;
    }

    .list-item-hower .menu-font-size .MuiListItemText-primary {
        font-size: 1rem !important;
    }

    .navbar-list-item {
        background-color: #dadada;
        border-radius: 10px;
        width: 260px;
        margin-bottom: 5px;
        cursor: pointer;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .list-item-hower {
        margin-bottom: 5px;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .navbar {
        padding-left: 15px !important;
    }

    .icon-font-size {
        font-size: small;
        height: 1em !important;
        width: 1em !important;
    }

}