.ticket-cards-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	padding-top: 10px;
	padding-bottom: 10px;
}

.ticket-cards-container2 {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	height: 360px;
	overflow: auto;
}

.ticket-cards-container2 .ticket-card {
	background-color: #d9d9d9 !important;
	border-radius: 10px;
	margin: 15px;
	/* width: 350px; */
}

.ticket-cards-container .ticket-card {
	background-color: white;
	border-radius: 10px;
	margin: 15px;
	/* width: 350px; */
}

.ticket-card:hover {
	cursor: pointer;
	background-color: rgb(226, 226, 226);
	transition: 0.5s;
}

.ticket-card:hover img {
	opacity: 0.9;
	transition: 0.5s;
}

.ticket-card-image {
	background-color: rgb(177, 177, 177);
	border-radius: 10px;
	width: 100%;
	/* min-width: 350px; */
	height: 200px;
}

.ticket-card-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.share-list-container {
	background-color: var(--buildid-lightgrey);
	border-radius: 10px;
}

.ticket-card-container {
	padding-top: 20px;
	padding-bottom: 20px;
	background: #fff;
	border-radius: 5px;
	box-shadow: 1px 2px 6px #979797;
	margin: 10px 0px;
}

.ticket-card-container .ticket-card {
	background: #bdbaba !important;
}

.remove-shadow {
	box-shadow: none !important;
}

.add-bg-color {
	background: var(--buildid-lightgrey) !important;
}

.remove-shadow .MuiTypography-body2 {
	font-size: 1rem !important;
}

.remove-shadow .MuiCardHeader-root {
	padding: 0px 10px !important;
}

.expand-btn {
	width: 50px;
	height: 50px;
}

.centered-message {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	margin-top: 90px;
}

.centered-message p {
	margin-top: 10px;
}

.centered-message svg {
	width: 80%;
	/* Set the icon width to 50% */
	height: auto;
	/* Maintain the aspect ratio */
	color: #979797;
}

.ticket-tutorial-2-body {
	width: 95%;
	margin-left: 71px;
}

.ticket-tutorial-2-stack {
	text-align: center;
	margin-top: 10.6%;
	gap: 10px;
}

.ticket-tutorial-2-tabs {
	margin-left: 190px;
	background-color: white;
	width: 350px;
}

.ticket-tutorial-3-body {
	text-align: center;
	color: white;
	padding: 256px;
}

.ticket-tutorial-3-ticket-card {
	border-radius: 10px;
	margin-top: 150px !important;
	margin-left: 95px;
	width: 385px;
	height: 324px;
}

.ticket-tutorial-4-body {
	margin-left: 18%;
	color: white;
	margin-top: 300px;
}