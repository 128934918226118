.modal-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.modal-bcg {
    background-color: #8D8D8D;
    width: 100%;
    height: 100vh;
}

.margin-card {
    margin: 5%;
    margin-bottom: 0;
}

.modal-card {
    background-color: white;
    border-radius: 10px;
    margin: 20px;
    padding-top: 10px;
}

.modal-card-header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px;
}

.modal-card-body {
    padding: 10px 30px 20px 30px;
}


@media screen and (max-width: 768px) {
    .modal-center {
        left: 50% !important;
        width: 100%;
    }

    .modal-card {
        height: 80vh;
        overflow: scroll;
        width: 100% !important;
    }

    .modal-card-body {
        padding: 15px;
    }

    .modal-card {
        width: auto !important;
    }

    .logo-Bulid-id {
        width: 125px !important;
    }

    .card-container {
        padding: 20px 0px !important;
    }

    .card-title {
        font-weight: 500 !important;
        font-size: 25px !important;
        padding-bottom: 0px !important;
    }

    .card-title-div {
        padding-bottom: 10px !important;
    }

    .card-desc-div {
        bottom: 30px !important;
        margin: 0px 10px !important;
    }

    .mute-icon {
        top: 90px !important;
    }

    .header-title {
        font-size: 30px !important;
        padding: 20px 0px !important;
    }

}

@media screen and (max-width: 550px) {
    .header-title {
        font-size: 30px !important;
        padding: 20px 0px !important;
    }

    .card-container {
        padding-top: 20px !important;
        padding-bottom: 30px !important;
    }

    .card-title {
        font-weight: 500 !important;
        font-size: 25px !important;
        padding-bottom: 0px !important;
    }

    .card-title-div {
        padding-bottom: 10px !important;
    }

    .card-desc-div {
        bottom: 30px !important;
        margin: 0px 10px !important;
    }

    .mute-icon {
        top: 90px !important;
    }

    .footer-div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


.logo-Bulid-id {
    width: 185px;
}

.header-title {
    color: white;
    font-weight: 600;
    font-size: 50px;
    padding: 40px 0px;
    line-height: 0px !important;
}

.card-container {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    /* overflow-x: auto; */
}

.card-title {
    color: gray;
    font-weight: 600;
    font-size: 35px;
    padding-bottom: 10px;
}

.card-title-div {
    display: flex;
    justify-content: center;
}

.card-descriptions {
    color: white;
    background-color: rgb(0 0 0 / 60%);
    font-weight: 400;
    font-size: 1em;
    padding: 5px 10px;
}

.card-desc-div {
    position: absolute;
    bottom: 35px;
    z-index: 2;
    margin: 0px 50px;
    box-sizing: border-box;
    width: auto;
    max-width: 500px;
}

.mute-icon {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 50px;
}

.footer-div {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
}


.montage-img-container {
    width: 500px;
    height: 500px;
    margin: auto;
}

.montage-img-container img {
    object-fit: contain;
}


.montage-img-container p {
    align-self: center;
    width: 100%;
    margin: auto;
}

body {
    overflow-x: hidden;
}

.css-13i4rnv-MuiGrid-root {
    overflow-x: unset;
}


[data-rbd-drag-handle-context-id="0"] {
    width: 200px;
    display: flex;
}

.css-xdmu94-MuiContainer-root {
    max-width: 915px !important;
    min-width: 500px !important;
}

.montage-tutorial-2-body {
    text-align: center;
    color: white;
    margin-top: 18.7%;
    margin-left: 120px;
}

.montage-tutorial-6-body {
    text-align: center;
    color: white;
    margin-top: 15.1% !important;
    margin-left: 74.4rem !important;
}

/* .carousel .slider-wrapper.axis-horizontal .slider {
    max-width: 800px;
} */

/* .custom-slider-container .MuiContainer-maxWidthMd {
  overflow: auto!important;
} */

/* .MuiContainer-root:has(.custom-slider-container) {
    overflow: auto!important;
} */