.timesheet-modal {
    /* max-height: 600px !important; */
    overflow: auto;
    /* margin-top: 50px !important; */
    background-color: var(--buildid-white);
    border-radius: 10px;
    padding: 0px !important;
    width: 700px;
}

.modal-card-header {
    position: sticky;
    top: 0;
    background: white;
    /* You may want to change the background color */
    z-index: 1;
}

.add-job-modal-body {
    background-color: rgba(0, 0, 0, 0.08);
    text-align: center;
    padding: 20px 0px 10px 0px;
}

.add-job-modal-body-icon {
    font-size: 10em !important;
    color: gray !important;
}

.timesheet-label {
    top: -8px !important;
    left: 15px !important;
}

/************************* Expand Time Sheet UI (11-10-2023) ****************************/

.timesheetinfo-card {
    box-shadow: none !important;
    border-top: 1px solid #80808063;
    border-radius: 0px;
}

.timesheetinfo-card .sheet-conent {
    padding-bottom: 10px !important;
}

.timesheetinfo-card .sheet-conent .project-name {
    font-size: 17px;
    font-weight: 500;
    color: #0000ffde;
}

.timesheetinfo-card .sheet-conent .project-img {
    padding: 10px 0px;
    overflow: hidden;
}

.timesheetinfo-card .sheet-conent .project-description {
    font-size: 15px;
    font-weight: 500;
}

.km-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0px 5px;
}

.km-dropdown::before {
    border-bottom: 0px !important;
}

.add-job-modal .job-list {
    height: 450px;
    overflow: auto;
}

.select-text:before {
    border-bottom: 0px !important;
}

.select-text {
    border: 1px solid #CCCCCC;
    padding: 5px;
}

.add-goal {
    font-size: 15px;
    text-transform: uppercase;
    color: #083DB8;
    font-weight: 600;
    padding: 0px 10px;
    cursor: pointer;
}

/*********** Selected Goals ***************/

.selected-goals {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.selected-goals .goals {
    padding: 2px 0px;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
}

.selected-goals .goals::before {
    border-bottom: 0px;
}

/******* Goal List ********/

.goallist-modal .goal-list {
    height: 350px;
    overflow: scroll;
    border-top: 1px solid #CCCCCC;
    padding-top: 0px;
}

.goallist-modal .goal-list .list-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
}

.goallist-modal .goal-list .list-item .heading {
    margin-bottom: 0px;
    font-size: 16px;
    padding: 0px;
}

.goallist-modal .goal-list .list-item .category-name {
    font-size: 13px;
}

.goallist-modal .goal-list .goal-item {
    border-bottom: 1px solid #CCCCCC;
}

.goallist-modal .goal-card {
    width: 600px;
}


/* Job list CSS  new code sanket 05-04-2024 */

.project-address {
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}


@media screen and (max-width: 768px) {
    .timesheet-modal {
        width: 85% !important;
    }
}