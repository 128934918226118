:root {
	--buildid-blue: #083db8;
	--buildid-yellow: #ffbc0a;
	--buildid-grey: #8d8d8d;
	--buildid-lightgrey: #cccccc;
	--buildid-white: #ffffff;
	--buildid-black: #000000;
	--buildid-lightred: #fabebe;
}

/* CSS Reset */
* {
	margin: 0;
	padding: 0;
}

/* Defined h's and p */
* h1,
* h2,
* h3,
* h4,
* h5,
* h6,
* p {
	margin: 0px 0px 5px 0px;
}

p.greytext {
	color: grey;
}

p.link,
span.link,
a.link {
	text-decoration: none;
}

p.link:hover,
span.link:hover,
a.link:hover {
	text-decoration: underline;
	cursor: pointer;
}

/* Special class for clickable MUI Icons */
svg.link:hover {
	cursor: pointer;
}

/* Page titles */
* h1 {
	color: var(--buildid-blue);
	font-size: 42px;
}

* h2 {
	font-size: 24px;
}

* h3 {
	font-size: 20px;
}

html {
	font-family: "Roboto";
}

body {
	margin: 0 !important;
}

.padding-lg {
	padding-top: 60px !important;
	padding-bottom: 60px !important;
}

/* Scrollbars (Chrome only) */
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

.trade-profile-user-list::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

::-webkit-scrollbar-thumb {
	background: var(--buildid-grey);
	border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.page {
	background-color: rgb(242, 242, 242);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	width: 100%;
    padding: 0px 10px;
    box-sizing: border-box;
	/* padding-left: 100px; */
}

.page-content {
	padding: 0 4%;
	display: flex;
	flex-direction: column;
}

.content-heading {
	flex-direction: row;
	justify-content: space-between;
	align-items: center
}

.content-heading h1 {
	font-size: 25px;
	margin-bottom: 0px;
}


body::-webkit-scrollbar,
.page-content::-webkit-scrollbar {
	width: 0px;
}

.page-sidebar {
	display: flex;
	flex-direction: column;
	height: 100vh;
	background-color: var(--buildid-white);
	border-right: 1px solid var(--buildid-lightgrey);
}

/*site photos tab*/

.picture-container-button {
	position: absolute;
	top: 16px;
	right: 16px;
	background-color: #808080ad;
	border-radius: 50%;
}

.picture-photo-container {
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--buildid-white);
	opacity: 75%;
	border-radius: 50%;
}

.picture-photo-star {
	position: absolute;
	top: 0;
	right: 0;
	background-color: var(--buildid-white);
	opacity: 75%;
	border-radius: 50%;
}

.picture-photo-SkipNextIcon,
.picture-photo-SkipPreviousIcon {
	position: absolute;
	opacity: 75%;
	border-radius: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.picture-photo-SkipNextIcon {
	right: -8px;
	background-color: var(--buildid-white);
}

.picture-photo-SkipPreviousIcon {
	left: 16px;
	background-color: var(--buildid-white);
}

/*******************/

/* Page header */
.header {
	/* background-color: var(--buildid-yellow); */
	padding-top: 20px;
}

.header-nav {
	background-color: var(--buildid-white);
	margin-bottom: 30px;
}

.header-nav h3 {
	text-transform: none;
}

.modal-center {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	outline: none;
}

.modal-card {
	background-color: white;
	border-radius: 10px;
	margin: 20px;
	padding-top: 10px;
	outline: none !important;
}

.modal-card-header-buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0px 10px;
}

.modal-card-body {
	padding: 10px 30px 20px 30px;
}

.feature-item {
	display: flex;
	align-items: center;
	margin-top: 15px;
  }
  
  .feature-icon {
	margin-right: 10px;
	color: var(--buildid-blue); /* Replace with your theme's primary color */
  }
  
  .button-group {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
  }
  
  .modal-button {
	background-color: var(--buildid-blue);
	color: white;
	width: 150px;
	height: 40px;
  }

  .modal-logo {
	display: block;
	margin: 0 auto 20px auto; /* Center the logo and add spacing below */
	width: 100px; /* Adjust width as needed */
	height: auto;
  }

  .section-card {
	background-color: #f5f5f5;
	padding: 15px;
	border-radius: 8px;
	margin: 10px 0;
	text-align: left;
  }
  
  .section-title {
	align-items: center;
	font-weight: bold;
	margin-bottom: 8px;
  }
  
  .data-list {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
  }
  
  .data-item {
	flex: 1 1 45%;
	font-size: 16px;
  }
  
  .agree-button {
	background-color: #3f51b5;
	color: white;
	width: 100%;
	height: 40px;
	margin-top: 20px;
	font-weight: bold;
  }

/* Force react-viewer (full screen picture viewer) to appear above MaterialUI's modal (which has a
	  z-index of 1300), eg. Connect page's channel setting's modal vs. channel picture viewer */
.react-viewer-mask,
.react-viewer-canvas,
.react-viewer-footer {
	z-index: 1301 !important;
}

.card,
.resp-card {
	background-color: white;
	border-radius: 5px;
	margin: 15px;
	padding: 15px;
	max-height: 600px;
	overflow: hidden;
}

.exp-card {
	background-color: white;
	border-radius: 5px;
	margin: 15px;
	padding: 15px;
}

/* Clickable card that turns grey on hover */
.resp-card:hover {
	background-color: var(--buildid-lightgrey);
	transition: 0.3s;
	cursor: pointer;
}

/* TODO refactor these to flex containers with space-between */
.flex-container-left {
	flex: 50%;
}

.flex-container-right {
	flex: right;
}

.flex-container-horizontal {
	display: flex;
	flex-direction: row;
}

.flex-container-vertical {
	display: flex;
	flex-direction: column;
}

/* Responsive horizontal flex container */
.resp-container-horizontal {
	display: flex;
	flex-direction: row;
}


.product-card .product-container {
	margin: 10px;
	margin-left: 5px;
	margin-right: 5px;
	padding: 5px 10px;
	border-radius: 4px
}

.product-card .product-container:hover {
	background-color: rgba(0, 0, 0, 0.04);
	cursor: pointer;
}


/* Changes flex-direction to column when screen gets narrow */
/* @media screen and (max-width: 1000px) {
	.resp-container-horizontal {
		flex-direction: column;
	}

	.resp-container-horizontal>* {
		width: 100%;
	}
} */

.input-container,
.content-container {
	margin: 10px;
	margin-left: 5px;
	margin-right: 5px;
}

/* Circle image container, with hover animation */
.circle-picture-container {
	position: relative;
	overflow: hidden;
	/* border-radius: 50%; */
}

.circle-picture-container img {
	position: block;
	border-radius: 50%;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

/* Standard rectangular image container */
/* Usage:
	  <div className="picture-container">
		  <div className="picture-container-button">
			  <IconButton
				  size="small"
				  onClick={() => }>
				  <CloseIcon/>
			  </IconButton>
		  </div>
		  
		  <img
			  src=""
			  alt=""/>
	  </div>
  */
.picture-container {
	display: inline-block;
	position: relative;
}

.picture-container img {
	/* height: 100%; */
	border-radius: 4px;
	max-height: 400px;
	width: 100%;
	object-fit: contain;
}

/*	Floating close button at top right of picture-container image
	  IconButtons don't have a prop to change background color, so have to use this container
	  
	  Usage:
	  <div className="picture-container">
		  <div className="picture-container-button"
			  onClick={() => }/>
		  ...
	  </div>
  */
/* .picture-container-button {
	  position: absolute;
	  top: 16px;
	  right: 16px;
	  background-color: var(--buildid-white);
	  opacity: 75%;
	  border-radius: 50%;
  } */

/*	Gray overlay on hover for imgs in (circle-)picture-containers
	  
	  Usage:
	  <div className="picture-container">
		  <div className="picture-container-overlay"
			  onClick={() => }/>
		  ...
	  </div>
  */
.picture-container-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	border-radius: 10px;
	height: 100%;
	width: 100%;
	transition: 0.3s;
}

.gm-style .gm-style-iw-c {
	display: none;
}

.gm-style .gm-style-iw-tc {
	display: none;
}

.carousel-root {
	display: flex;
	justify-content: center;
}

.picture-container-overlay:hover {
	/* Hardcoded overlay colour with opacity */
	background-color: #00000052;
	cursor: pointer;
	transition: 0.3s;
}

/* Grey line divider */
.divider {
	padding-top: 20px;
	padding-bottom: 20px;
}

.divider p {
	width: 100%;
	text-align: center;
	border-bottom: 1px solid grey;
	line-height: 0.1em;
	margin: 10px 0 20px;
}

.divider p span {
	background: var(--buildid-white);
	padding: 0 10px;
}

.changelog-body p {
	margin: 10px 0px;
}

.changelog-body li {
	margin: 10px 0px 10px 30px;
}

.settingsWrapper {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(2rem, 0rem));
	gap: 1rem;
	justify-content: center;
}

.settingsCard {
	overflow: hidden;
	display: contents;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	transition: transform 200ms ease-in;
}

.settings__img {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	transition: transform 200ms ease-in;
	max-width: 100%;
	height: auto;
	padding-bottom: 20px;
	object-fit: cover;
}

.PrivateTabIndicator-colorSecondary-3 {
	background-color: transparent !important;
}

.css-19kzrtu {
	padding-top: 0px !important;
}

.tab-btn-radius {
	background-color: #ffffff !important;
	border-radius: 45px 0px 0px 45px !important;
}

.resultLenght {
	background-color: white;
	margin-top: 16px;
	width: 140px;
	display: flex;
	justify-content: center;
	border-radius: 5px;
	padding: 10px;
	color: gray;
}

.pac-container {
	z-index: 9999 !important;
	width: 430px !important;
}

.pac-container .pac-item {
	line-height: 40px;
	cursor: pointer;
}

.pac-container .pac-item .pac-icon {
	margin-top: 12px;
}

.modal-center .modal-card .user-list {
	height: 300px;
	overflow: scroll;
}

.infinite-scroll-component::-webkit-scrollbar {
	width: 0px;
}

.scrollable-container {
	min-height: 20px;
	max-height: 300px;
	overflow-y: auto;
}



.job-grid {
	border: 1px solid rgb(229, 234, 239);
	border-radius: 7px;
	padding: 10px;
	margin-bottom: 15px;
}

.upload-image-div {
	display: -webkit-inline-box;
	width: 100%;
	overflow: auto;
	padding-bottom: 6px;
	column-gap: 10px;
}

.job-grid .upload-image {
	display: flex;
	border: 2px solid rgb(229, 234, 239);
	justify-content: center;
	height: 125px;
	width: 185px;
	margin-right: 10px;
	border-radius: 7px;
}

.store-card-image1 {
	background-color: var(--buildid-grey);
	border-radius: 10px;
	width: 155px;
	height: 125px;
	cursor: pointer;
	position: relative
}

.store-card-image1 img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.store-card-colse-icon1 {
	position: absolute;
	cursor: pointer;
	right: 5px;
	top: 5px;
	background-color: #808080ad;
	border-radius: 50px
}

.store-card-colse-icon1-color {
	color: white;
	background-color: red;
	border-radius: 50px
}

.expCardPadding1 {
	padding: 0px 15px 4px 15px !important;
}

.expCardPadding2 {
	padding: 0px 15px 15px 15px !important;
}

.expCardBgc {
	background-color: rgb(242, 242, 242) !important;
	margin-bottom: 10px;
}

.list-container {
	height: 81vh;
	overflow: auto;
	margin-top: 5rem;
	margin-bottom: 10px;
}

.list-container::-webkit-scrollbar {
	width: 0px;
}

#scrollableDiv::-webkit-scrollbar {
	width: 0px;
}

/* Builder network contact card */

.contact-list {
	height: 450px;
	overflow: scroll;
	margin-top: 10px !important;
}

.request-sent {
	background: #ffff;
	color: rgb(8, 61, 184);
	border: 1px solid rgb(8, 61, 184);
}

.css-3mfg68-Icon {
	background-color: #ffbc0a !important;
}

.add-content {
	background: #b73e3b;
	border-radius: 50%;
	display: flex;
	align-items: center;
}

.add-content .add-icon {
	font-size: 35px;
	color: #ffff;
	cursor: pointer;
}

.exp-card-project-img{
	margin-right: 15px;
	cursor: pointer;
	width: 80px;
	height: 55px;
	border-radius: 5px;
}

/* .parent-inline-div{
	padding: 0% 36% 0% 24%;
} */
@media screen and (max-width: 768px) {

	.exp-card-project-img{
		width: 60px !important;
		height: 45px !important;
		border-radius: 5px !important;
	}

	.MuiTypography-body2-317{
		font-size: 0.675rem !important;
	}

	.css-10hburv-MuiTypography-root{
		font-size: 0.8rem !important;
	}

	.css-83ijpv-MuiTypography-root{
		font-size: 0.675rem !important;
	}

	.MuiTypography-body2-902{

	font-size: 0.675rem !important;

	}

	.resp-container-horizontal {
		flex-direction: column;
	}

	.resp-container-horizontal {
		width: 100%;
	}

	.card,
	.resp-card {
		margin: 10px 0px;
		padding: 20px 10px;
	}

	.page-content {
		padding: 5px;
	}

	.flex-container-vertical {
		margin-left: 0px !important;
	}

	.carousel .slide img {
		object-fit: cover !important;
	}

	.sidebar {
		width: 70px;
	}

	.user-id {
		padding: 10px;
	}

	.activity-flex,
	.profile-flex {
		display: inline-block !important;
	}

	.flex-container-right {
		display: inline-block !important;
	}

	.rewards-input-container .MuiButton-outlined {
		padding: 5px 8px !important;
	}

	.rewards-input-container {
		padding: 10px 5px !important;
	}

	/* .profile-header-details {
		display: block;
	} */

	.in-container {
		width: 100% !important;
	}

	.store-cards-container {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}

	.resp-container-horizontal {
		margin: 0px !important;
	}

	.ticket-card {
		margin: 15px 0px !important;
		width: 100% !important;
	}

	.ticket-cards-container {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}

	.ticket-card-image {
		min-width: 100%;
	}

	.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
		margin: 15px !important;
	}

	.MuiTabs-fixed {
		overflow: scroll !important;
	}

	.sidebar-content {
		padding-left: 80px !important;
		padding-right: 15px !important;
	}

	.search-container {
		width: 100% !important;
	}

	.padding-lg {
		padding-top: 20px !important;
		padding-bottom: 60px !important;
	}

	.MuiFormControlLabel-root {
		margin-left: -5px !important;
	}

	.PrivateSwitchBase-root-1 {
		margin-top: 20px !important;
	}

	.modal-center {
		left: 50% !important;
		width: 100%;
	}

	.modal-card {
		height: 80vh;
		overflow: scroll;
		width: 100% !important;
	}

	.modal-card-body {
		padding: 15px;
	}

	.flex-container-right {
		margin-left: 0px !important;
	}

	.content-container {
		margin: 15px 0px !important;
	}

	.ticket-card-image {
		height: auto;
	}

	.MuiSvgIcon-root {
		fill: currentColor !important;
	}

	.modal-card {
		width: auto !important;
	}

	.profile-pic-in-profile{
		        height: 100px;
		        width: 100px;
		        /* margin-right: 15px; */
	    }
	    .trade-detail{
		        width: 70% !important;
	    }
	    .user-name{
		        font-size: 20px !important;
	    }
	    .mobile-menu{
		        margin-top: 30px;
		        margin-left: 40px;
		        height: 40%;
		        padding: 0px;
		
	        .mobile-menu-item{
		            margin-top: -10px;
		            margin-bottom: -15px;
	        }
	        #mobile-menu-msg{
		            margin-bottom: -20px;
	        }
		}
	    .primary-search-account-menu{
		        top: -136px !important;
		        left: 13.4px !important;
		        .menu{
		            margin-top: -15px !important;
		            margin-bottom: -5px !important;
	        }
    }
	    .Notification-menu{
		        width: 100% !important;
	    }
	    .mobile-menu-img{
		        height: 3% !important;
		        width: 35px !important;
	    }

}


input[type=number] {
	-moz-appearance: textfield;
    -webkit-appearance: none;   /* Chrome, Safari, Opera */
    appearance: none;           /* Standard */
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.search_address_div {
	padding: 18.5px 14px;
	border: 1px solid #bdbdbd;
	border-radius: 5px;
}


/* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */
    @media only screen and (min-width: 960px) {
        /* styles for browsers larger than 960px; */
    }
    @media only screen and (min-width: 1440px) {
        /* styles for browsers larger than 1440px; */
		.dashboard-page {
			background-color: rgb(242, 242, 242);
			min-height: 93vh;
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 0px 10px;
			box-sizing: border-box;
			/* padding-left: 100px; */
		}
		.parent-inline-div{
			/* max-height: 100vh; */
			padding: 0% 34% 0% 20%;
		}
		
    }
    @media only screen and (min-width: 2000px) {
        /* for sumo sized (mac) screens */
    }
    @media only screen and (max-device-width: 480px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
	   /* .parent-inline-div{ */
		/* max-height: 80vh; */
	/* } */
	
	.dashboard-page {
		background-color: rgb(242, 242, 242);
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0px 10px;
		box-sizing: border-box;
		/* padding-left: 100px; */
	}
	.parent-inline-div{
		/* max-height: 100vh; */
		padding: 0%;
	}

	.scrollablediv_post{
		min-height: 85vh !important;
	}

	.profile-pic {
		height: 60px;
		width: 60px;
		object-fit: cover;
	}
	.profile-header-details {
		margin-top: 0px;
	}
	}
    @media only screen and (device-width: 768px) {
       /* default iPad screens */
	   .dashboard-page {
		background-color: rgb(242, 242, 242);
		min-height: 96vh;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0px 10px;
		box-sizing: border-box;
		/* padding-left: 100px; */
	}
	.scrollablediv_post {
		max-height: 830px !important;
		overflow-y: auto;
	}
	.parent-inline-div{
		/* max-height: 100vh; */
		padding: 0% 5% !important;
	}

	.profile-pic {
		height: 60px;
		width: 60px;
		object-fit: cover;
	}
	.profile-header-details {
		margin-top: 0px;
	}
    }
    /* different techniques for iPad screening */
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
      /* For portrait layouts only */
	  .dashboard-page {
		background-color: rgb(242, 242, 242);
		min-height: 97vh;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0px 10px;
		box-sizing: border-box;
		/* padding-left: 100px; */
	}
	.scrollablediv_post {
		max-height: 1330px !important;
		overflow-y: auto;
	}

	.parent-inline-div{
		/* max-height: 100vh; */
		padding: 0% 15% !important;
	}
	.profile-pic {
		height: 60px;
		width: 60px;
		object-fit: cover;
	}
	.profile-header-details {
		margin-top: 0px;
	}
	}

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
      /* For landscape layouts only */
    }
/**** Builder buks count in dashboard ****/

.builderbuks-count .an-count {
	font-size: 14px;
    margin: 15px 15px 0px 15px;
    color: #8d8d8d;
}
.builderbuks-count  .an-info {
	display: flex;
    flex-direction: column;
    align-items: center;
}

.builderbuks-count .an-info .cup-icon {
	color: #ffbc0a;
    font-size: 70px;
}

.builderbuks-count  .an-info .answer-count {
	font-size: 20px;
    color: #083db8;
    font-weight: 600;
    margin: 0px;
}

.builderbuks-count  .an-info .more-info {
    font-size: 14px;
    color: #8d8d8d;
}

.scrollablediv_manufacturer{
    max-height: 615px !important;
    overflow-y: auto;
}

.scrollablediv_ListSection{
    max-height: 495px !important;
    overflow-y: auto;
}

.css-nvf14r-ToastContainer {
	top: 45px !important;
}
.close-btn {
	position: absolute !important;
    background-color: red !important;
    z-index: 99999;
    border-radius: 50% !important; 
    color: white !important;
    border: none !important;
    height: 20px;
    width: 20px;
    top: 0px;
    right: 0px;
    transform: translate(30%, -35%);
}

.pulse {
 
    animation: pulse 1s infinite;
 
}
 
@keyframes pulse {
    0% {
        background-color: red;
    }
 
    50% {
        background-color: #ffffff;
    }
 
    100% {
        background-color: red;
    }
}