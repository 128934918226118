.store-card {
	background-color: white;
	border-radius: 10px;
	/* margin: 15px 0px; */
}

.store-cards-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(388px, 1fr));
	padding-top: 15px;
	padding-bottom: 15px;
	gap: 30px;
}

.store-card-image {
	background-color: var(--buildid-grey);
	border-radius: 10px 10px 0px 0px;
	width: 100%;
	height: 250px;
}

.store-card-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px 10px 0px 0px;
}

.store-header {
	background-color: var(--buildid-blue);
	padding: 15px 20px;
	border-radius: 10px;
}

.store-header p {
	font-size: 16px;
	color: var(--buildid-white);
	margin-bottom: 10px;
}

.store-header h1,
#buckBalance {
	font-size: 32px;
	font-weight: bold;
	color: var(--buildid-white);
	margin: 0px;
}

.store-header h1 span {
	font-size: 24px;
	font-weight: normal;
	color: var(--buildid-white);
	margin-left: 5px;
}


.store-card-content {
	padding: 20px;
}

.store-card-title {
	font-size: 17px;
	color: var(--buildid-blue);
	font-weight: bold;
	line-height: 20px;
	/* margin-bottom: 10px; */
}

.store-card-title-new {
	font-size: 20px;
	color: var(--buildid-blue);
	font-weight: bold;
}

.store-card-btn {
	width: 160px;
	border-radius: 10px !important;
	font-size: 16px !important;
	text-transform: unset !important;
}

.store-card-content-point {
	font-size: 14px;
}

.store-card-content-point span {
	float: right;
	color: var(--buildid-grey);
}


.rewards-input-container {
	padding: 10px;
	padding-left: 5px;
	padding-right: 5px;
}

.rewards-input-container input {
	padding: 10.5px 14px;
	height: 30px;
}

.rewards-input-container {
	padding: 20px 5px !important;
}

.earnBucks .reward-next-btn-container button {
	text-transform: capitalize;
	font-weight: 700;
	padding-left: 40px;
	padding-right: 40px;
}


.rewards-page {
	min-height: 0vh !important
}

.rewards-help-icon-container svg {
	/*color: #fff;*/
	cursor: pointer;
}

.rewards-help-modal-header {
	background: var(--buildid-yellow);
	border-radius: 8px 8px 0 0;
	padding: 20px !important;
}

.rewards-help-modal-card {
	padding-top: 0px !important;
	overflow-y: scroll;
	height: 100%;
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.rewards-help-modal-card::-webkit-scrollbar {
	display: none;
}

.help-modal-title {
	color: #000000;
}

.help-modal-footer-info {
	text-align: center;
	padding: 20px 0px;
}

.help-card-body {
	padding: 10px 0px 20px 0px !important;
}

.help-modal-profile-image img {
	width: 128px;
	height: 128px;
}

.community-container {
	text-align: center;
}

.community-container h3 {
	color: #000000;
}

.community-container p {
	margin: 15px 0px;
}

.rewards-help-modal {
	width: 75%;
	height: 80vh;
	overflow: hidden;
}

.adding-contacts-container {
	width: 60%;
	padding: 30px 0px;
	margin: auto;
}

.adding-contacts-container p {
	margin-bottom: 10px;
}

.adding-contacts h4 {
	font-size: 20px;
	margin: 18px 0px;
}

.blue-text {
	color: var(--buildid-blue);
	font-weight: bold;
	/* padding-left: 10px;
	padding-top: 5px; */
	align-items: center;
    display: flex;
}

.white-bg {
	padding-top: 0px !important;
	padding: 64px;
	background-color: var(--buildid-white);
}

.dark-bg {
	padding: 45px;
	background-color: #EDEDED;
}

.modal-projects {
	text-align: center;
	padding: 0px 15px;
	border-radius: 5px;
	background: transparent;
	transition: transform 0.5s, background 0.5s;
}

.modal-projects h4 {
	font-size: 20px;
}

.modal-projects p {
	margin-top: 18px;
}

.modal-projects svg {
	font-size: 50px;
	color: var(--buildid-grey);
	margin-bottom: 32px;
}

.help-modal-profile-image svg {
	font-size: 70px;
	color: var(--buildid-grey);
}

.help-modal-profile-image {
	display: table-cell;
	vertical-align: middle;
}

.help-modal-adding-contacts svg {
	font-size: 150px;
	color: var(--buildid-grey);
}

.help-modal-adding-contacts {
	display: table-cell;
	vertical-align: middle;
}

.knowledge-container {
	display: table;
	margin: 0px 55px;
}

.ellipsis {
	font-size: 16px !important;
	margin-bottom: 0px !important;
	color: #000000 !important;
}

.reward_quantity_input {
	text-align: center !important;
	background-color: red !important;
}

.btn-width {
	min-width: 40px !important;
}

@media screen and (max-width: 768px) {
	.store-card {
		margin: 10px 0px;
	}

}