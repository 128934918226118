.resumebuilder-tutorial-3-body{
     width: 100%; 
}
.resumebuilder-tutorial-3-box{
    text-align: center;
    margin-top: 12.4%;
    width: 80.8%;
    margin-left: 15.7%;
    margin-bottom: 20px;
}
.resumebuilder-tutorial-3-cardcontent{
    display: flex;
    justify-content: space-between;
    width: 70vw;
}
.resumebuilder-tutorial-4-body{
    margin-left: 15%;
    color: white;
    margin-top: 280px;
}
.resumebuilder-tutorial-4-info-card{
    width: 590;
    margin-left:24px;
    margin-top:-32px;
}

/*  Mini Tutorial  */
 
.resumebuilder-mini-tutorial-body{
    width: 100%;
}
.resumebuilder-mini-tutorial-box{
   position: absolute;
   right: 10px !important;
   text-align: center;
   margin-right: 15.3%;
   margin-top: 1.4%;
   width:22%;
   height: 10%;
   margin-bottom: 20px;
}
.resumebuilder-mini-tutorial-cardcontent{
   display: flex;
   justify-content: space-between;
   align-items:center,
}