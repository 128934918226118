/* Dashboard notifications container */
.dashboard-container {
	flex: 1;
	padding: 20px;
	overflow-y: scroll;
}

/* Individual notification card */
.dashboard-notification {
	background-color: var(--buildid-white);
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 10px;
}

/* System generated notifcation (from Construction Database)
	Different styling compared to normal notifications */
.dashboard-notification-sysgen {
	background-color: var(--buildid-blue);
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 10px;
}

.dashboard-notification-sysgen h2,
.dashboard-notification-sysgen p {
	color: var(--buildid-white);
}


/* Adds an overlay to a notification */
.dashboard-notification-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100%;
	width: 100%;
	transition: 0.3s;
}

.dashboard-notification:hover .dashboard-notification-overlay,
.dashboard-notification-sysgen:hover .dashboard-notification-overlay {
	/* Hardcoded overlay colour with opacity */
	background-color: #00000052;
	cursor: pointer;
	transition: 0.3s;
}

.dashboard-notification-dismiss-button {
	display: none;
}

.dashboard-notification:hover .dashboard-notification-dismiss-button,
.dashboard-notification-sysgen:hover .dashboard-notification-dismiss-button {
	display: block;
	transition: 0.3s;
}



.newsfeed-tabs-group {
	margin-top: 16px;
	background-color: white;
}

.newsfeed-comment-card {
	background-color: rgb(245, 244, 244) !important;
	/* TODO Hardcoded colour */
	margin-bottom: 4px;
	box-shadow: none !important;
}

.newsfeed-comment-header .MuiCardHeader-content span {
	font-size: small !important;
}


.newsfeed-comment-image img {
	width: 100%;
	border-radius: 4px;
	cursor: pointer;
}

/* .addpost-card .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
	height: 0.4375em;
} */

/* .addpost-card .css-46bh2p-MuiCardContent-root:last-child {
	padding-bottom: 16px;
} */

/* .addpost-card .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
	font-size: 0.9rem;
} */


/* new code sanket 29-03-2024 */

.btn-listing {
	display: flex !important;
	flex-direction: row !important;
	gap: 15px;
	margin-top: 9px;
}

.btn-listing .btn-post {
	background: #ffff;
	color: #000;
	border-radius: 18px !important;
	font-size: 15px !important;
	text-transform: capitalize !important;
	padding: 6px 10px !important;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px
}

.CardContent-bottom-Padding {
	padding: 12px !important;
}

.scrollablediv_post {
	max-height: 550px;
	overflow-y: auto;
}

.dashboard-inlineMargin {
	margin-inline: 18rem 29rem;
}

.dashboard-inlineMargin-reward {
	margin-inline: 18rem 29rem !important;
}

.dashboard-inlineMargin-post {
	margin-inline: 18rem 28.8rem;
}

.fab-icon-dashboard {
	position: absolute;
	right: 20px;
	top: 650px;
}

.expanded-card {
	width: 325px;
	position: absolute;
	right: 15px;
	/* top: expanded ? '610px' : '70px', */
	top: 610px;
	z-index: 99999;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	border-radius: 15px;
}

.expanding-card {
	width: 325px;
	position: absolute;
	right: 15px;
	top: 70px;
	z-index: 99999;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	border-radius: 15px;
}

.home-tutorial-2 {
	width: 75%;
	margin-left: 478px;
}

.home-tutorial-2 .home-tutorial-2-stack {
	text-align: center;
	color: white;
	margin-top: 4.44%;
}

.home-tutorial-3-main-div {
	display: flex;
	justify-content: center;
}

.home-tutorial-3-main-div .home-tutorial-3-body {
	text-align: center;
	color: white;
	margin-top: 2.2%;
	width: 38.4vw;
}

@media screen and (max-width: 768px) {
	.dashboard-container {
		padding: 0px;
	}

	.dashboard-notification-sysgen {
		padding: 15px;
	}

	.notification-block {
		padding: 0px !important;
		margin-right: 10px !important;
	}

}

.postComment-modal {
	width: 600px !important;
	max-height: 580px !important;
}
.postAdImage{
	display: flex;
	justify-content: center;
}

/* @media only screen and (min-width: 770px) and (min-width: 1200px) */

@media screen and (min-width: 1920px) {

	.dashboard-inlineMargin {
		margin-inline: 22rem 36rem !important;
	}

	.dashboard-inlineMargin-post {
		margin-inline: 22rem 35.8rem !important;
	}

	.tempDiv {
		display: flex;
		width: 200px;
		height: 100px;
		background-color: red;
	}

	.scrollablediv_post {
		min-height: 790px !important;
		overflow-y: auto;
	}

	.fab-icon-dashboard {
		position: absolute;
		right: 35px;
		top: 825px;
	}

	.expanding-card {
		width: 345px;
	}

	.expanded-card {
		width: 345px;
		top: 825px;
	}

	.postComment-modal {
		width: 750px !important;
		max-height: 750px !important;
	}
}


/* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */
    @media only screen and (min-width: 960px) {
        /* styles for browsers larger than 960px; */
    }
    @media only screen and (min-width: 1440px) {
        /* styles for browsers larger than 1440px; */
    }
    @media only screen and (min-width: 2000px) {
        /* for sumo sized (mac) screens */
    }
    @media only screen and (max-device-width: 480px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
	   .parent-inline-div{
		/* max-height: 80vh; */
	}

	.scrollablediv_post{
		min-height: 80vh !important;
	}
	}
    @media only screen and (device-width: 768px) {
       /* default iPad screens */
    }
    /* different techniques for iPad screening */
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
      /* For portrait layouts only */
    }

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
      /* For landscape layouts only */
    }