.chat-screen {
    background: #8D8D8D;
    /* height: 85vh !important; */
    min-height: 94vh !important;
    padding-top: 3.3rem !important;
}

.chat-screen .chatgrid-main {
    background: #ffff;
}

/* .chat-screen .chatgrid-main .MuiInputBase-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: 2rem;
    background-color: white;
} */

.chat-screen .chatgrid-main .userlist-grid {
    padding-left: 0px !important;
    padding-top: 0px !important;
}

.chat-screen .chatgrid-main .userlist-grid .user-search {
    margin: 10px 30px 10px 30px;
    background: #ffff;
}

.chat-screen .chatgrid-main .userlist-grid .search-field {
    font-family: Roboto;
    font-size: 14px;
    color: #083DB8;
    display: flex;
    justify-content: end;
    font-weight: 500;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 5px;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist {
    overflow-x: hidden !important;
    height: 74vh;
    overflow: scroll;
    border-top: 1px solid #CCCCCC;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist::-webkit-scrollbar {
    width: 0px;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist .user-item {
    display: flex;
    align-items: center;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist .user-item.active::before {
    width: 4px;
    border-right: 4px solid #D3D3D3;
    content: "";
    height: 2.6rem;
    position: absolute;
    display: flex;
    border-color: #FFBC0A;
}

.Autocomplete-height {
    height: 370px;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist .list-item:hover {
    background: #ffff;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist .list-item {
    align-items: self-start;
    justify-content: space-between;
    padding: 10px 12px;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist .list-item .list-item-text {
    overflow: hidden;
    display: -webkit-box;
    width: 160px;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    margin: 0;
    -webkit-box-orient: vertical;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist .list-item .msg-time {
    position: absolute;
    right: 10px;
    font-size: 12px;
    white-space: nowrap;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist .list-item .last-message {
    font-size: 13px;
    color: #8D8D8D;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist .list-item .unread-msg {
    background: #083DB8;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 2px;
    position: absolute;
    right: 25px;
    top: 28px;
}

.chat-screen .chatgrid-main .userlist-grid .chat-userlist .list-item .unread-msg .unreadmsg-count {
    font-size: 11px;
    color: white;
}


/***************** Chat Conversation Screen ********************/

.chat-grid {
    border-left: 1px solid #CCCCCC;
    padding: 0px !important;
}

.chat-grid .chat-conversation {
    display: flex;
    flex-direction: column;
}

.chat-grid .chat-conversation .message-time {
    font-size: 12px;
    color: #8D8D8D;
}

.chat-grid .chat-conversation .message-paper {
    padding: 5px;
}

.chat-grid .chat-conversation .message-paper .conversation-img {
    height: 100px;
    width: 100px;
}

.chat-grid .chat-conversation .message-paper .messages {
    font-size: 15px;
}

.chat-grid .chat-conversation .send-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.chat-grid .chat-conversation .send-box .send-icon {
    font-size: 34px;
    color: #083DB8;
    cursor: pointer;
}

.chat-grid .chat-conversation .send-box .send-icon.disabled-color {
    color: grey;
}

.chat-grid .chat-conversation .message-paper .messagelink {
    display: flex;
    gap: 10px;
    width: 210px;
    cursor: pointer;
}

.chat-grid .chat-conversation .message-paper .messagelink .site-detail {
    display: flex;
    flex-direction: column;
}

.chat-grid .chat-conversation .message-paper .messagelink .site-detail .site-name {
    margin-bottom: 0px;
    font-weight: 500;
}


.chat-grid .chat-conversation .message-link {
    display: flex;
    background: #CCCCCC;
    border-radius: 5px;
    margin: 0px 10px;
    padding: 10px;
    gap: 10px;
    position: relative;
    margin-top: 5px;
}

.chat-grid .chat-conversation .message-link .link-img {
    height: 70px;
    width: 70px;
    border-radius: 5px;
}

.chat-grid .chat-conversation .message-link .site-name {
    margin-bottom: 0px;
    font-size: 17px;
    font-weight: 500;
}

.chat-grid .chat-conversation .message-link .site-type {
    font-size: 16px;
    margin: 0px;
}

.chat-grid .chat-conversation .message-link .close-link {
    position: absolute;
    right: 10px;
    background: #8D8D8D;
    border-radius: 50px;
    color: white;
    display: flex;
    padding: 3px;
    cursor: pointer;
}

.chat-grid .chat-conversation .message-link .close-link .close-icon {
    font-size: 17px;
}


.chat-grid .chat-conversation .preview-message-link {
    display: flex;
    border-radius: 5px;
    margin: 0px 10px;
    padding: 10px 10px 0px 10px;
    gap: 10px;
    position: relative;
    margin-top: 5px;
}

.chat-grid .chat-conversation .preview-message-link .link-img {
    height: 70px;
    width: 70px;
    border-radius: 5px;
}

.chat-grid .chat-conversation .preview-message-link .site-name {
    margin-bottom: 0px;
    font-size: 17px;
    font-weight: 500;
}

.chat-grid .chat-conversation .preview-message-link .site-type {
    font-size: 16px;
    margin: 0px;
}

.chat-grid .chat-conversation .preview-message-link .close-link {
    position: absolute;
    top: -5px;
    left: 60px;
    background: #8D8D8D;
    border-radius: 50px;
    color: white;
    display: flex;
    padding: 0px;
    cursor: pointer;
}

.chat-grid .chat-conversation .preview-message-link .close-link .close-icon {
    font-size: 17px;
}

.chat-grid .chat-conversation .conversation-header {
    display: flex;
    align-items: center;
    gap: 5px;
}

.image-preview-container {
    position: relative;
}


/************* Add New Chat Modal *****************/

.add-new-chat .newchat-card {
    width: 650px;
    padding: 0px;
    border-radius: 5px;
}

.add-new-chat .newchat-card .header-section {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 15px;
}

.add-new-chat .newchat-card .newchat-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.add-new-chat .newchat-card .newchat-list .newuser-list {
    padding: 0px 15px;
}

.list-item .trade-name {
    font-size: 12px;
    color: #8D8D8D;
}

.list-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.add-new-chat .newchat-card .footer-btnbox {
    display: flex;
    justify-content: center;
    padding: 13px 0px;
}

.add-new-chat .newchat-card .footer-btnbox .submit-btn {
    background: #083DB8;
    border: 0px;
    color: white;
}

.add-new-chat .newchat-card .footer-btnbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 13px 0px;
}

.loader-style {
    display: block;
    position: absolute;
    top: 45%;
    left: 56%;
    margin: auto;
}

.chat-grid .chat-conversation .message-paper .fab-messages {
    font-size: 13px;
}

.fab-loader {
    height: 600px;
    display: flex;
    align-items: center;
}

.fab-senBox {
    display: flex;
    gap: 3px;
    padding: 5px;
    padding-right: 0px;
}

.fab-conversation-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 325px;
    background-color: #FFBC0A;
    padding: 10px 5px;
    padding-left: 15px;
}

.fab-screen-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 325px;
    cursor: pointer;
}

.fab-chat-conversation {
    height: 580px;
    border-radius: 50px;
}

/*--------------------------------------------*/
/* Mini Mobile with All Mobile Devices (480px) View*/
/*--------------------------------------------*/
@media all and (max-width: 480px) {
    .chat-grid {
        display: none;
    }

    .userlist-grid {
        display: none;
    }

    .chat-screen .page-content {
        padding: 0px 10%;
    }
}

@media screen and (min-width: 1920px) {

    .fab-conversation-screen {
        width: 345px;
    }

    .fab-screen-header {
        width: 345px;
    }

    .fab-chat-conversation {
        height: 755px;
        border-radius: 50px;
    }

    .fab-loader {
        height: 810px;
        display: flex;
        align-items: center;
    }

    .chat-screen {
        background: #8D8D8D;
        min-height: 95vh !important;
        padding-top: 3rem;
    }
}