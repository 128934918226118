.other-tab {
	background-color: transparent;
}

.other-tab ul {
	margin-top: 7px !important;
	padding-top: 0px;
	padding-bottom: 0px
}

.all-tab ul {
	padding-top: 0px;
	padding-bottom: 0px
}

.all-tab {
	background-color: White;
	margin-top: 7px;
	padding: 0px 5px;
	/* padding: 15px 0px; */
	border-radius: 4px;
	box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%),
		0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.infinite-scroll::-webkit-scrollbar {
	width: 0px;
}


/********** Universal Search 24-11-2023************/

.universal-search {
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.universal-search .search-type {
	font-size: 17px;
	font-weight: 500;
	margin-bottom: 10px;
}

.universal-search .type-list .list-item {
	border-radius: 5px;
	background: #ffff;
	padding: 13px !important;
	gap: 7px;
	cursor: pointer;
	width: 90%;
}

.universal-search .type-list .list-item.active {
	background: #FFBC0A;
	color: #ffff;
}

.universal-search .type-list .list-item .item-checked {
	display: none;
}

.universal-search .trade-list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 13px;
	letter-spacing: 0px;
}

.universal-search .trade-list .list-item {
	background: #ffff;
	border-radius: 50px;
	padding: 6px 10px;
	cursor: pointer;
}

.universal-search .trade-list .list-item.active {
	background: #FFBC0A;
	color: #ffff;
}

.universal-search .trade-list .list-item .trade-name {
	font-size: 14px;
}

.universal-search .advanced-accordion {
	border-radius: 5px;
	position: unset;
	margin: 15px 0px;
	background: #CCCCCC;
	box-shadow: none;
	border: 1px solid #00000024;
}

.universal-search .advanced-accordion .Mui-expanded {
	min-height: 14px !important;
	padding: 3px 9px;
}

.universal-search .advanced-accordion .show-more {
	float: right;
	font-size: 13px;
	color: #0000ff;
	padding: 5px 0px;
	cursor: pointer;
}

.universal-search .advanced-accordion.Mui-expanded {
	margin: 15px 0px;
	background: #CCCCCC;
	box-shadow: none;
	border: 1px solid #00000024;
}

.universal-search .advanced-accordion .advanced-summary .Mui-expanded {
	margin: 0px;
}

.universal-search .advanced-accordion .location-search .search-place {
	margin-bottom: 14px;
	background: #ffff;
	border-radius: 5px;
}

.universal-search .advanced-accordion .location-search .search-place .MuiOutlinedInput-notchedOutline {
	border-color: transparent;
}

.universal-search .advanced-accordion .quick-location .quickloc-grid {
	margin: 14px 0px;
}

.universal-search .advanced-accordion .quick-location .quickloc-grid .firstQuick {
	border: 0px;
	background: white;
	border-radius: 5px;
	cursor: pointer;
	padding: 9px 10px;
}

.universal-search .advanced-accordion .quick-location .quickloc-grid .firstQuick.active {
	background: #FFBC0A;
	color: #ffff;
	cursor: pointer;
}

.search-container {
	width: 35%;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-top: 10px;
}

.search-page-header {
	position: sticky;
	z-index: 1000;
	top: 111px;
	background-color: var(--buildid-lightgrey)
}

.product-list {
	 display: flex;
	 position: fixed;
	 width: 80%;
	 margin-top: 5rem;
}