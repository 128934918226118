.sidebar {
    width: 100px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    border-right: 1px solid var(--buildid-lightgrey);
    z-index: 100;
}

.sidebar:hover {
    width: 270px;
}

.sidebar:hover .user-id span {
    width: 55px;
    height: 55px;
}

.iconHover {
    position: absolute;
    visibility: hidden;

    /* visibility: unset; */

}

.sidebar:hover .iconHover {
    position: relative;
    padding-left: 20px;
}

.subMenuIcon {
    padding-left: 80px;
}

.promo-button {
    padding: 0px 16px;
    display: none;
}

.sidebar:hover .user-id>div,
.sidebar:hover .iconHover,
.sidebar:hover .promo-button,
.sidebar:hover .main-nav a span,
.sidebar:hover .lower-menu a span {
    opacity: 1;
    transform: translate(0px);
    visibility: visible;
    display: block;
}

.sidebar:hover .main-nav a {
    text-align: left;
    display: flex;
}

.sidebar:hover .user-id p {
    font-size: 18px;
    margin: 0 0 0 10px;
}

.user-id {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #fdf7e9;
}

.user-id:hover {
    background-color: #fae4b0;
}

.user-id img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0;
    transition: all 0.5s;
}

.user-id p {
    font-size: 0;
    line-height: 1.2;
    color: #000;
    transition: all 0.5s;
}

.user-id span img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.user-id>div {
    flex: 1;
    transition: all 5s;
    opacity: 0;
    transform: translate(-60px);
    visibility: hidden;
    display: none;
}

.main-nav {
    list-style: none;
    overflow: scroll;
}

.main-nav a {
    padding: 15px 25px;
    text-align: center;
    display: block;
    font-size: 14px;
    color: #202020;
    text-decoration: none;
}

.main-nav a:hover {
    background: #fde3a7;
}

.main-nav .active a {
    background: #ffb900;
}

.main-nav a span,
.lower-menu a span {
    transition: all 0.5s;
    position: relative;
    display: inline-block;
    opacity: 0;
    transform: translate(-60px);
    visibility: hidden;
    display: none;
    padding-left: 20px;
    padding-top: 5px;
}

.main-nav a svg {
    font-size: 1.5rem;
}

.lower-menu {
    padding: 0;
    margin: auto 0 0px;
    list-style: none;
}

.lower-menu a {
    font-size: 16px;
    color: #777777;
    padding: 12px;
    display: flex;
    text-decoration: none;
}

.lower-menu a i {
    font-size: 18px;
    margin-right: 10px;
}

.lower-menu a:hover {
    color: #000000;
}

/* .version {
    font-size: 14px;
    color: #777777;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 15px;
} */
