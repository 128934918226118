.login{
	display: flex;
}
/* Reset font colours */
.login h1,
.login h2,
.login h3,
.login p{
	color: black;
	font-weight: 100;
}
.login h1{
	font-size: 72px;
}
.login h2{
	font-size: 48px;
}
.login h3{
	font-size: 24px;
}
.login p{
	font-size: 16;
	color: grey;
}

.login-form{
	position: absolute;
	width: 40%;
	height: 100%;
	top: 0;
	left: 0;
}

.login-form-content-width{
	/* width: 25%; */
	width: 35%;
}

.login-form-content{
	position: absolute;
	top: 50%;
	left: 65%;
	width: 45%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.login-splash{
	position: absolute;
	background: url(../images/login-bg.png) no-repeat center center/ cover;
	width: 60%;
	height: 100%;
	top: 0;
	right: 0;
}

.login-onboarding-form{
	display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.login-splash-content{
	position: absolute;
	top: 50%;
	left: 40%;
	width: 45%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.login-type-title{
  margin-bottom: 15px;
}


.login-buildid-logo{
	width: 60%;
	height: auto;
}

.login-fotter{
	position: absolute;
    bottom: 5px;
	right: 10px;
    width: 100%;
    display: flex;
	justify-content: flex-end;
	column-gap: 10px;
	font-size: 12px;
}

.login-fotter a{
	text-decoration: none;
	cursor: pointer;
	color: black;
}


@media screen and (max-width: 768px) {
	.login-form {
		width: 85%!important;
		height: 100%;
		top: 29px!important;
		left: 11px!important;
		padding: 15px;
	}	

	.login-splash {
		width: 100%;
		height: 86%!important;
		top: 100%!important;
		right: 0;
	}

	.login-form-content {
		top: 47%!important;
		left: 50%!important;
		width: 100%!important;
	}

	.login h1 {
		font-size: 50px!important;
	}

	.login-splash-content {
		top: 42%;
		left: 50%;
		width: 90%;
		padding: 0px 15px;
	}

	.login-form-content h3 {
		text-align: center;
		margin-bottom: 50px;
	}
  }