.timelist-card {
    box-shadow: none !important;
    border: 1px solid #cccccc;
    border-radius: 15px !important;
    padding: 15px;
    margin-bottom: 15px;
}

/******************* Crew Modal ******************/

.crew-modal {
    padding: 20px !important;
}

.crew-modal .profile-img {
     height: 40px;
     width: 40px;
     border-radius: 50%; 
}

/****************** Selected Employess *************/

.selectedemp-box {
    height: 250px;
    overflow: scroll;
}

.selectedemp-box::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

.selected-empcard {
    padding: 10px;
    border: 1px solid #cccccc;
    box-shadow: none !important;
    margin-top: 15px;
}

/******************* Job Activities Modal ******************/

.jobActivities-list {
    overflow: scroll;
    height: 445px;
    padding-bottom: 5px;
}

.jobActivities-list .jobActivities-card {
    box-shadow: none;
    border: 1px solid #cccccc;
    padding: 10px;
    margin-bottom: 15px;
}

/******************* Image Box ******************/

.add-pic {
    height: 100px;
    width: 100%;
    border: 1px solid #cccccc;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    cursor: pointer;
    object-fit: cover;
}

/******************* Reviews Box ******************/

.reviews-box::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

.reviews-box {
    height: 480px;
    overflow: scroll;
    padding-bottom: 10px;
    margin-bottom: 5px;
}

.reviews-box .activity-card,
.reviews-box .emp-card {
    box-shadow: none;
    border: 1px solid #cccccc;
    padding: 10px;
    margin-bottom: 10px;
}

.reviews-box .emp-card .emp-name {
    width: 150px;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}


/* Blur the background content */
.content-blur {
    filter: blur(5px);
    /* Adjust blur strength */
    pointer-events: none;
    /* Disable interaction with the blurred content */
}

/* Loader overlay */
.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    /* Semi-transparent white background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* Ensure it sits on top */
}