.jobposting-tutorial-2-body{
    width: 75%;
    margin-left: 190px;
}
.jobposting-tutorial-3-body{
    width: 75%;
    margin-left: 187px;
    margin-top:-64.4px;
}
.jobposting-tutorial-3-card{
    background: #d8d8d8;
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}