.buysell-pic-list{
	gap: 8px 2px !important;
	margin-bottom: 12px;
}

.buysell-pic-item{
	height: 110px !important;
	width: 110px;
}

.buysell-img{
	height: 110px;
	border-radius: 10px;
	width: 110px;
}

.buysell-close-icon{
    align-items: center;
	position: absolute;
	background: #000000a3;
	border-radius: 50%;
	height: 19px;
	width: 19px;
	right: 5px;
	display: flex;
	top: 5px;
	justify-content: center;
	cursor: pointer;
}

.buysell-icon-cl{
    color: #ffff;
	font-size: 16px;
}


.buysell-add-picture{
	background: #e4e4e4;
	display: flex;
	justify-content: center;
	border-radius: 5px;
	height: 90px;
	align-items: center;
	margin-bottom: 10px;
	gap: 5px;
	cursor: pointer;
}


.buysell-addpic-inimagelist{
    height: 110px;
	margin-bottom: 0px;
	gap: 0px;
}

.buysell-addpicture-text{
	font-size: 11px;
}