.carpool-tutorial-2-body{
    width: 99%;
    margin-left: 368px;
    margin-bottom: 90px;
}

.carpool-tutorial-2-button{
    margin-left: 67.5% !important;
    margin-top: 14.8% !important;
}

.carpool-tutorial-3-body{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130%;
    margin-right: 32%;
}
.carpool-tutorial-4-body{
    height: 100%;
    align-items: center;
    margin-left: 50%;
    padding: 70px;
}