.site-map-container {
	flex-grow: 1;
	outline: none;
	height: 93vh;
}

.site-map-pin-label {
	border: 3px solid black;
	border-radius: 10px;
	padding: 5px;
	background-color: var(--buildid-white);
	bottom: 50px;
	left: 0;
	position: relative;
}

.site-overlay-container {
	position: absolute;
	max-height: 100%;
	width: 445px;
	display: flex;
	flex-direction: column;
}


/* Mahindra Code 28-mar-2024 */
.site-overlay-container2 {
	position: absolute;
	max-height: 100%;
	width: 300px;
	display: flex;
	flex-direction: column;
	background-color: white !important;
}

.site-overlay-container3 {
	position: absolute;
	max-height: 100%;
	width: 400px;
	display: flex;
	flex-direction: column;
	background-color: white !important;
}

.site-overlay-container .MuiOutlinedInput-adornedEnd {
	height: 38px;
}
.site-overlay-container3 .MuiOutlinedInput-adornedEnd {
	height: 38px;
}

/* Mahindra Code 28-mar-2024 */
.site-overlay-container2 .MuiOutlinedInput-adornedEnd {
	height: 41px;
}

/* Fixed height and proper scaling of img for ProjectDetail's Carousel */
.site-detail-carousel-image {
	height: 337px;
	/* site-overlay-container has a width of 450; 337 x 450 is a 4:3 ratio */
	background-color: var(--buildid-grey);
}

.site-detail-carousel-image img {
	object-fit: cover;
	/* Fill container, may clip part of image */
	/* height and width 100% ensure that it's centered if it's clipped */
	height: 100%;
	width: 100%;
}

.site-map-container .add-iconbtn:hover {
	background-color: #002886;
}

.site-map-container .add-nearbtn:hover {
	background-color: #002886;
}

/* Mahindra Code 28-mar-2024 */
.site-map-container .add-iconbtn1:hover {
	background-color: #ececec;
}

/* Mahindra Code 28-mar-2024 */
.site-map-container .add-iconbtn2:hover {
	background-color: #ececec;
}

/* Mahindra Code 28-mar-2024 */
.site-map-container .add-iconbtn1 {
	position: absolute;
	right: 10px;
	top: 10px;
	background-color: white;
	border-radius: 5px;
	color: #083DB8;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.site-map-container .searchbar-map {
	position: absolute;
	left: 10px;
	top: 10px;
	border-radius: 5px;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.site-map-container .activeSitesFlag {
	border-radius: 5px;
	padding-left: 15px;
	position: absolute;
	bottom: 15px;
	left: 45%;
	background: rgb(255, 255, 255);
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.site-map-container .chooseSites {
	border-radius: 5px;
	position: absolute;
	top: 10px;
	left: 44%;
	background: rgb(255, 255, 255);
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	padding: 10px;
	width: 350px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.site-map-container .add-iconbtn {
	position: absolute;
	right: 10px;
	background-color: #083DB8;
	border-radius: 5px;
	color: #ffff;
	bottom: 24px;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.site-map-container .add-nearbtn {
	position: absolute;
	right: 10px;
	background-color: #083DB8;
	border-radius: 5px;
	color: #ffff;
	bottom: 80px;
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

/* Mahindra Code 28-mar-2024 */
.site-map-container .add-iconbtn2 {
	position: absolute;
	right: 10px;
	top: 10px;
	background-color: white;
	border-radius: 10px;
	color: #083DB8;
}

.site-map-container .mapIcon {
	position: absolute;
	right: 50%;
	bottom: 50%;
	transform: translate(50%, 0%);
}

.add-iconbtn-tutorial-conatiner .add-iconbtn-tutorial {
	background-color: #083DB8;
	border-radius: 10px;
	color: #ffff;
	margin-left: 50px;
	z-index: 9999;
}


.add-iconbtn-tutorial-conatiner .add-iconbtn-dashboard-tutorial {
	background-color: #083DB8;
	border-radius: '50%';
	color: #ffff;
	margin-left: 50px;
	z-index: 9999;
}

.site-map-container .help-iconbtn:hover {
	background-color: #083DB8;
}

.site-map-container .help-iconbtn {
	position: absolute;
	right: 10px;
	top: 4rem;
	background-color: #083DB8;
	border-radius: 10px;
	color: #ffff;
}



/************************** Site Detail 17-10-2023 ************************/

.site-view {
	position: fixed;
	height: 91vh;
	min-width: 445px;
	width: 445px;
	margin: 8px;
}

.site-view-tutorial {
	min-width: "450px";
	width: 450px;
}

.site-view .site-info {
	padding: 10px 10px 10px 10px !important;
	border-top: 1px solid #CCCCCC;
}

.site-view .site-info .site-name {
	font-size: 20px;
	margin-bottom: 0px;
}

.site-view .site-info .site-address {
	font-size: 13px;
}

.site-view .add-site .add-siteheading {
	font-size: 13px;
	margin-bottom: 0px;
	font-weight: 400;
	color: #000000;
}

.site-view .add-site {
	border-bottom: 1px solid #CCCCCC;
}

.site-view .add-site .site-address {
	font-size: 13px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.6);
}

/************************** Add Site 18-10-2023 ************************/

.add-site-modal .add-picture {
	background: #e4e4e4;
	display: flex;
	justify-content: center;
	border-radius: 5px;
	height: 90px;
	align-items: center;
	margin-bottom: 10px;
	gap: 5px;
	cursor: pointer;
}

.add-site-modal .addsite-card {
	padding: 0px 20px;
	width: 800px;
	height: 620px;
	overflow-y: auto;
}

.addsite-main {
	height: 600px;
	overflow-y: auto;
}

.add-site-modal .addsite-card::-webkit-scrollbar {
	height: 0;
	width: 0;
}

.add-site-modal .addsite-card .header-section {
	justify-content: space-between;
	display: flex;
	align-items: center;
	padding: 0px;
}

.add-site-modal .add-picture .addsite-title {
	font-size: 17px;
	font-weight: 400;
}

.add-site-modal .add-picture .addpicture-text {
	font-size: 16px;
	font-weight: 400;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.6);
}

.add-site-modal .addsite-card .main {
	margin-top: 10px;
}

.add-site-modal .addsite-card .main .site-info .title {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 12px;
}

.add-site-modal .addsite-card .main .site-info .suggested-site {
	color: #083db8;
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
}

.suggested-site {
	color: #083db8;
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
}

.add-site-modal .addsite-card .main .site-info .changeloc-btn {
	background: white;
	color: #083DB8;
	border-color: #083DB8;
	border: 1px solid #083DB8;
	box-shadow: none;
	font-size: 13px;
	margin-bottom: 14px;
}

.add-site-modal .addsite-card .main .sitepic-list {
	gap: 8px 2px !important;
	margin-bottom: 12px;
}

.add-site-modal .addsite-card .main .sitepic-list .sitepic-item {
	height: 110px !important;
	width: 110px;
}

.add-site-modal .addsite-card .main .sitepic-list .sitepic-item .site-img {
	height: 110px;
	border-radius: 10px;
	width: 110px;
}

.add-site-modal .addsite-card .main .sitepic-list .sitepic-item .addpic-inimagelist {
	height: 110px;
	margin-bottom: 0px;
	gap: 0px;
}

.add-site-modal .addsite-card .main .sitepic-list .sitepic-item .addpic-inimagelist .addpicture-text {
	font-size: 11px;
}

.add-site-modal .addsite-card .main .sitepic-list .sitepic-item .close-icon {
	align-items: center;
	position: absolute;
	background: #000000a3;
	border-radius: 50%;
	height: 19px;
	width: 19px;
	right: 5px;
	display: flex;
	top: 5px;
	justify-content: center;
	cursor: pointer;
}

.add-site-modal .addsite-card .main .sitepic-list .sitepic-item .close-icon .icon-cl {
	color: #ffff;
	font-size: 16px;
}

/********************** Choose Site Option 19-10-2023 ************************************/

.choose-opt .chooseopt-card {
	/* height: 150px; */
	width: 400px;
	padding: 0px !important;
}

.choose-opt .chooseopt-card .header-section {
	justify-content: space-between;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #80808085;
	padding: 5px 15px;
}

.choose-opt .chooseopt-card .header-section .chooseopt-title {
	font-size: 17px;
}

.choose-opt .chooseopt-card .options {
	display: flex;
	justify-content: space-around;
	margin-top: 16px;
}

.choose-opt .chooseopt-card .options .select-loc {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;
	cursor: pointer;
}

.choose-opt .chooseopt-card .options .select-loc .insert-btn {
	background: #8d8d8d69;
	border-radius: 10px;
	height: 40px;
	width: 40px;
}

.choose-opt .chooseopt-card .options .select-loc .insert-btn .opt-icon {
	color: #0826b8;
}

.choose-opt .chooseopt-card .options .select-loc .select-text {
	font-size: 15px;
	color: #000000;
}

/********************** Choose Location 19-10-2023 ************************************/

.choose-location .location-card {
	/* height: 450px; */
	width: 800px;
}

.choose-location .location-card .chooseloc-footer {
	position: absolute;
	bottom: 0px;
	width: 100%;
	background: white;
	text-align: center;
	padding: 10px 10px;
}

.choose-location .location-card .chooseloc-footer .currentloc {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	cursor: pointer;
}

.choose-location .location-card .chooseloc-footer .chooseloc-btn {
	background: #083DB8;
	color: #ffff;
}

/********************** My site listing 19-10-2023 ************************************/

.mysite-list {
	height: 71vh;
	overflow: auto;
	will-change: transform;
	transition: transform 0.3s ease;
}

.nolocation-mysite-list {
	height: 76vh;
	overflow: auto;
	will-change: transform;
	transition: transform 0.3s ease;
}
.sighup-mysite-list {
	padding-left: 16px;
	height: 63vh;
	width: 420px;
	overflow: auto;
	will-change: transform;
	transition: transform 0.3s ease;
}

.noSite-list {
	height: 76vh;
	overflow: auto;
	will-change: transform;
	transition: transform 0.3s ease;
}

.mysite-list .myprofile-txt {
	font-size: 14px;
	color: #8D8D8D;
	padding: 5px 10px;
}

.nolocation-mysite-list .myprofile-txt {
	font-size: 14px;
	color: #8D8D8D;
	padding: 5px 10px;
}

.mysites-card {
	display: flex;
	gap: 9px;
	padding: 5px 13px;
	align-items: flex-start;
	margin-top: 4px;
	box-shadow: none !important;
	justify-content: space-between;
}

.mysites-card .site-content {
	display: flex;
	gap: 9px;
	align-items: flex-start;
	cursor: pointer;
}

.mysites-card .site-img {
	height: 48px;
	width: 48px;
	border-radius: 10px;
	border: 1px solid #8D8D8D;
}

.mysites-card .list-content {
	padding: 0px !important;
}

.mysites-card .list-content .site-name {
	font-size: 17px;
	margin-bottom: 3px;
}

.mysites-card .list-content .site-address {
	font-size: 13px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	overflow: hidden;
	-webkit-box-orient: vertical;
	color: #908c8c;
}


.scrollableMinDiv {
	max-height: 575px !important;
	overflow-y: auto;
}

.scrollableMaxDiv {
	max-height: 415px;
}

/* .gmnoprint {
	display: none;
}

.gm-bundled-control {
	display: none;
}

.gm-bundled-control-on-bottom {
	display: none;
} */

.site-tutorial-2-body {
	text-align: center;
	color: white;
	margin-top: 10%;
}

.site-tutorial-3-body {
	text-align: center;
	color: white;
	margin-bottom: 28px;
	margin-right: 11px;
}

.site-tutorial-4-body {
	text-align: center;
	color: white;
	margin-top: 57px;
	margin-left: 195px;
}

.site-tutorial-4-textField {
	border-radius: 8px;
	margin: 8px;
	margin-bottom: 0px;
}

.site-tutorial-4-notification {
	width: 8px;
	height: 8px;
	background-color: red;
	border-radius: 50%;
	position: absolute;
	top: 4px;
	right: 4px;
}

@media screen and (max-width: 768px) {
	.site-overlay-container {
		width: 315px;
	}
}

@media screen and (max-width: 550px) {
	.site-overlay-container {
		width: 300px;
	}
}

@media screen and (max-width: 768px) {
	.modal-card {
		/* height: auto!important; */
		overflow: scroll;
		width: 90% !important;
	}

	.thankyou-screen {
		width: 80% !important;
	}

	.with-referral {
		width: 90% !important;
	}
}

@media screen and (min-width: 1920px) {

	.add-site-modal .addsite-card {
		padding: 20px;
		width: 650px;
		height: 68vh;
		overflow: auto;
	}

	.nolocation-mysite-list {
		height: 82vh;
		overflow: auto;
		will-change: transform;
		transition: transform 0.3s ease;
	}

	.mysite-list {
		height: 78vh !important;
		overflow: auto;
		will-change: transform;
		transition: transform 0.3s ease;
	}


	.site-map-container {
		flex-grow: 1;
		outline: none;
		height: 95vh;
	}

	.noSite-list {
		height: 82vh;
		overflow: auto;
		will-change: transform;
		transition: transform 0.3s ease;
	}

	.site-view {
		position: fixed;
		height: 93vh;
		min-width: 445px;
		width: 445px;
		margin: 8px;
	}

	.scrollableMinDiv {
		max-height: 770px !important;
		overflow-y: auto;
	}

	.scrollableMaxDiv {
		max-height: 575px;
	}
}

.gm-style .gmnoprint {
	display: none !important; /* Hides the attribution and error reporting */
  }
.gm-style .gm-style-cc a {
	display: none !important; /* Hides the attribution and error reporting */
  }
