.add-expenses {
    background: white;
    width: 600px;
    border-radius: 10px;
    outline: 0px;
}

.add-expenses .addexpenses-form {
    padding: 20px;
    height: 650px;
    overflow: scroll;
}

.add-expenses .addexpenses-form::-webkit-scrollbar {
     width: 0px;
}

.add-expenses .addexpenses-form .heading {
    font-size: 18px;
    margin: 0px;
    font-family: "Roboto";
    padding: 0px;
}

.add-expenses .addexpenses-form .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.add-expenses .addexpenses-form .close-btn {
    background: red;
    display: flex;
    align-items: center;
    height: 30px;
    width: 30px;
    align-items: center;
    color: #ffff;
}

.add-expenses .addexpenses-form .form-field  {
    background: rgb(242, 242, 242);
    border: none !important;
    margin-top: 10px;
    border-radius: 11px;
}

.add-expenses .addexpenses-form .form-field .MuiOutlinedInput-notchedOutline {
     border-color: #ffff;
}

.add-expenses .addexpenses-form .form-label {
     font-size: 15px;
     color: #4f4f4f;
}

.add-expenses .addexpenses-form .upload-btn {
    border: 1px solid #b73e3b;
    color: #b73e3b;
    text-transform: capitalize;
}

.add-expenses .addexpenses-form .submit-btn {
    background: rgb(176, 67, 62);
    color: white;
    text-transform: capitalize;
    font-size: 15px;
}

.add-expenses .addexpenses-form .clientjob-card {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: flex;
    padding: 10px;
    flex-direction: column;
}

.add-expenses .addexpenses-form .receipt-docunment {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 100px;
    justify-content: flex-start;
    
}

.add-expenses .addexpenses-form .receipt-docunment .recipt-img {
    height: 100px;
    width: 100px;
    border-radius: 10px;
}
