
@media screen and (max-width: 768px){
    .modal-card {
        height: auto!important;
        overflow: scroll;
        width: 90% !important;
    }

    .thankyou-screen{
        width: 80%!important; 
    }

    .with-referral{
		width: 90%!important;
	}

    
}


.thankyou-screen{
    width: 22%; 
    height: 70vh; 
    display: flex;
    flex-direction: column; 
    text-align: center;
}

.login {
    /* height: 100vh; */
    align-items: center;
}